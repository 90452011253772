@import './sass/variables';
@import '@fgb/portal-component-library/styles';
// @import '@fgb/portal-component-library/fonts/icon-font/css/simple-line-icons.min.css';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
@import 'ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
@import 'ngx-toastr/toastr';
@import 'bootstrap/scss/bootstrap';

/* ELEMENT STYLING */

html {
  background-image: none;
  min-height: 100vh;
  width: 100%;

  body {
    background: $background-color;
    width: 100%;
    font-family: $font-default;
    @media screen and (max-width: 991px) {
      padding-top: 0px;
    }
  }
}

/* BOOTSTRAP EXTENSIONS */

.cursor-pointer {
  cursor: pointer !important;
}

.btn-circle {
  border-radius: 50%;
}

/* ON COLOURS */

@each $color, $value in $theme-colors {
  .text-on-#{$color} {
    color: color-contrast($value, $dark, $light) !important;
  }

  .svg-#{$color} {
    color: $value;

    .cls-1 {
      fill: $value !important;
      stroke: $value !important;
    }

    .circle {
      fill: $value !important;
      stroke: $value !important;
    }
  }
}

/* Form Colors */

.form-control {
  color: #000000;
}

.modal {
  .modal-content {
    border-radius: 15px;
  }
}

.scorecard-modal-content {
  .modal-content {
    border-radius: 10px;
  }
}

/* PopUp Notifications */
#action-popup-body {
  .btn:disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.importantPopup {
  .modal-content {
    border: 5px solid red;
  }
}

.fullScreenPopup {
  .modal-content {
    width: 100vw;
    height: 100vh;
  }

  .modal-dialog {
    position: absolute;
    margin: 0;
  }
}

.toast-container .ngx-toastr {
  display: block;
  width: 370px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 4px;
  overflow: auto;
  color: #000;
  padding: 0 10px 15px 15px;
}

.font-heading {
  font-family: $font-heading;
}

/* Placeholder Styling */

.no-items {
  .no-results-placeholder-title {
    font-size: 1rem;
    font-family: $font-heading;
  }

  .no-results-placeholder-text {
    font-size: 0.75rem;
  }
}

/* Scorecard Styling */

.scorecard-description-carousel-header .scorecard-description-imgs .scorecard-active {
  margin: 0;
}

fgbcl-scorecard-description-header {
  .scorecard-description-prize {
    padding: 0 5px;
  }
}

.scorecard-description-prizebox {
  padding: 10px;
}

.scorecard-number-counter,
.counter-number-scorecard {
  font-size: 30px !important;
}

.scorecard,
.scorecard-description-actions .action-completed {
  border: none !important;
}

.scorecard-name,
#scorecard-name,
.scorecard-punchcard-steps {
  font-family: $font-heading !important;
  color: $white;
}

.scorecard-description,
#scorecard-description {
  color: $white;
}

.scorecard-description-carousel-header {
  background-color: $secondary-color;
  .scorecard-description-prize {
    flex-direction: column;
  }
  .scorecard-description-description {
    font-size: 0.75rem;
  }
}

.scorecard-description-page {
  overflow-x: hidden;
}

.scorecard-item-terms-url {
  color: $tertiary-color;
}

.scorecard-description-carousel-header .scorecard-description-back-btn,
.scorecard-description-name,
.scorecard-description-description,
.scorecard-description-actions .scorecard-actions-completed,
.scorecard-milestone-value,
.scorecard-description-milestone-value {
  color: $white !important;
}

.scorecard-description-milestone-value,
.scorecard-milestone-value {
  font-family: $font-heading;
}

.scorecard-prize-label {
  font-family: $font-heading;
  color: $tertiary-color;
}

.scorecard-prize-text {
  color: $tertiary-color;
}

.scorecard-description-prize {
  .prize-label,
  .prize-content {
    color: $tertiary-color !important;
  }
}

.scorecard-description-prize {
  .prize-label {
    font-family: $font-heading;
  }
}

.scorecard-description-prize {
  .prize-content {
    font-family: $font-default;
    font-weight: normal;
  }
}

.scorecard-description-linked-action .scorecard-description-prizebox {
  .scorecard-description-prize,
  .scorecard-description-prize-description {
    color: $black;
  }
}

.scorecard-description-actions {
  background-color: $black;
  color: $white;
}

.scorecard-description-actions .wrap-scorecard-punches .scorecard-punchcardview-incompleted {
  background-color: $secondary-color;
  border-radius: 16px;
}

.scorecard-description-completed-text,
.scorecard-description-action-completed {
  font-family: $font-heading;
}

.scorecard-description-back-text,
.scorecard-punch-text-model,
.scorecard-description-locked-box-text {
  font-family: $font-heading;
}

.scorecard-description-actions .action-incompleted {
  background-color: $info-color;
  border: none !important;
}

.scorecard-description-desc-terms,
.scorecard-description-prize,
.scorecard-description-child-name {
  font-family: $font-heading;
}

fgbcl-scorecard-description-body {
  .accordion-button,
  .accordion-item {
    background-color: $black;
    color: $white !important;
  }
  .short-long-description-text {
    font-family: $font-default !important;
    color: $white !important;
    text-transform: none;
  }
  .scorecard-item-terms-text {
    font-family: $font-default !important;
    text-transform: none;
  }

  .sponsor-img-text {
    .sponsor-img {
      height: 40px;
      width: 80px !important;
    }
    .sponsor-label {
      font-size: 0.75rem;
    }
  }

  .accordion-item {
    border: var(--bs-accordion-border-width) solid $secondary-color;
  }
}

.scorecard-container,
.scorecard-locked {
  background-color: $secondary-color !important;
}

.sponsor-label {
  color: $white;
}

.scorecard-locked-footer {
  font-family: $font-heading;
  color: $white;
}

.scorecard-description-carousel-header {
  .next-btn,
  .previous-btn {
    color: $white;
  }
}

.counter-number-scorecard {
  color: $white;
}

fgbcl-scorecard-list-view {
  .col-lg-4.col-12.mb-4 {
    z-index: 1;
  }
}

.scorecard-linked-shadow {
  position: relative !important;
  &::before {
    background: $info-color;
    border: 1px solid $info-color;
    z-index: -1;
  }
  &::after {
    border: 1px solid #dedddd;
    background: #dedddd;
    opacity: 0.6;
    z-index: -2;
  }
}

.scorecard-prize-border {
  .scorecard-label-ellipsis {
    color: $tertiary-color !important;
    max-width: 85%;
  }
}

.scorecard-description-button:not(.collapsed)::after,
.scorecard-term-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-icon);
}

.progress-bar {
  background: transparent linear-gradient(90deg, #863efe 0%, #80acfc 100%) 0% 0% no-repeat;
}

.progress,
.circle-empty {
  background-color: $info-color;
}

.Sponsor-img-text {
  .sponsor-img {
    object-fit: cover;
  }
}

.scorecard-description-linked-action .scorecard-description-congratulation-title {
  font-family: $font-heading;
  color: $tertiary-color;
  text-transform: capitalize;
}

.scorecard-description-congratulation-subtitle,
.scorecard-description-next-challenge-label {
  color: $white;
}

.scorecard-description-linked-action .scorecard-description-prizebox {
  background-color: $tertiary-color;
}

.scorecard-description-linked-action .scorecard-description-next-challenge-box {
  background-color: $secondary-color;
  color: $white;
}

.scorecard-description-more-btn {
  font-family: $font-heading;
  color: $white;
}

.scorecard-description-actions .scorecard-desciprtion-time-completed {
  opacity: 1;
}

.scorecard-description-linked-action .scorecard-description-locked-box {
  border: 1px solid $info-color;
  color: $white;
}

fgb-master-page {
  fgbcl-scorecard-description {
    fgb-footer {
      display: none !important;
    }
  }
}

.scorecard-description-share {
  display: none !important;
}

/* Voucher Styling */

.card {
  .card-body {
    .result-box {
      .result {
        p {
          color: $white !important;
          font-size: 0.75rem;
          padding-top: 5px;
        }
      }
    }
  }
}

/* BADGE STYLING */

.badge-overview-list.badges-list-container {
  .badge-subheading {
    color: $text-color;
    text-align: center;
  }

  .badge-title {
    font-family: $font-heading;
    color: $white;
    font-size: 0.875rem;
  }
}

/* FAQS Styling */

.faq-container {
  .faq-section-description,
  .accordion {
    .card {
      background-color: #000000;
      border-radius: 15px !important;
      &-header {
        background-color: #5a2e80 !important;
        border-radius: 15px 15px 0 0 !important;
        .faq-question-titles {
          font-weight: bold;
        }
      }
      &-body {
        box-shadow: none !important;
        background-color: $primary-color !important;
        color: $text-color;
        border: none !important;
        border-radius: 0 0 15px 15px !important;
      }
    }
  }
}

fgbcl-faq-section-card {
  .faq-section-title {
    color: $white !important;
    font-size: 1rem !important;
  }

  .accordion-item {
    border-color: $info-color !important;
    background-color: $black;
    color: $white;
    font-size: 0.875rem;
  }

  button {
    &.faq-section-card-title {
      font-size: 0.875rem;
      background-color: $black;
      color: $white;
    }
  }
}

/* Registration Terms */

.registration-term {
  .terms-section-box {
    border: none;
  }

  .accept-box {
    display: flex;
  }
  input[type='checkbox'] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em !important;
    height: 1.15em !important;
    border: 1px solid currentColor;
    border-radius: 3px;
    display: grid;
    place-content: center;
  }

  input[type='checkbox']::before {
    content: '';
    width: 0.75em;
    height: 0.75em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #5a2e80;
    border-radius: 3px;
  }
  input[type='checkbox']:checked::before {
    transform: scale(1);
  }
}

.modal-dialog {
  .modal-content {
    color: $white;
    background-color: $black;

    .material-icons.text-black,
    p.text-danger {
      color: $white !important;
    }

    input,
    select {
      border-radius: 15px;
      background-color: $primary-color;
      color: $white;
      border-color: transparent;
    }

    #cancelButton,
    #saveButton,
    .btn-cancel,
    .btn-confirm {
      border-radius: 15px;
      border-color: transparent;
      max-width: 142px;
      width: 100%;
      font-family: $font-heading;
      font-weight: bold;
    }

    #cancelButton,
    .btn-cancel {
      background-color: $white;
      color: #000;
    }

    #saveButton,
    .btn-confirm {
      background-color: $secondary-color;
    }
  }
}

.confirm-modal {
  .modal-body {
    font-weight: bold;
    display: flex;
    justify-content: center;

    > div {
      width: 100%;
      max-width: 220px;
    }
  }
}

fgbcl-country-and-regions {
  .invalid-feedback {
    font-size: 12px;
    color: #fff !important;
  }

  select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6ElEQVR4nO3UMUoDQRTG8dUiWAg2QtLnAFaWWnkFS9tcwSt4B0t7wcJSbRTWImdIkyZCyhAQyU8WNhDGZXeSTCPMH6Z73/cxb+a9oshkMpkU4BVjDBN6DmvPl7aiNXNcJQi9xNfaNCa44ge3e4SO8L1pWLQUl/5yj94Wgb1aE1K2iY7x2CD6wCAi9BRvDfpnnHSJD6oWYxWIpzhv0Z1hEmgqjzscdrZqw+gai8BoiZt9aqPousWu3YkCfbw3vNtTfUKq2n6c++4/NeQBR0VqNMxmipmPAheYpd5y2+zfz/ok2+uZTOZ/8QttCRmgpVxKagAAAABJRU5ErkJggg==');
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 12px;
    background-size: 12px;
  }
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: $white;
}

.owl-theme .owl-dots .owl-dot span {
  background: #313131;
}

fgb-registration-layout-page {
  fgbcl-registration-terms-and-conditions {
    h5 {
      text-transform: capitalize !important;
      font-size: 22px !important;
      margin-bottom: 20px !important;
      margin-top: 20px !important;
    }
    .terms-section-box {
      border: none;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }

    .accept-box-label,
    .btn {
      font-family: $font-heading;
    }
  }
  .btn-previous-step {
    background-color: $white !important;
    border-color: $black !important;
    color: $black !important;
  }

  .btn-next-step {
    background-color: $black !important;
  }

  .registration-navigation-button {
    justify-content: space-between !important;
  }

  .wrap-accept-box {
    padding-bottom: 0;
  }

  .wrap-accept-box .accept-box-label {
    padding-left: 0;
  }

  .accept-box {
    input {
      accent-color: $black;
    }
  }
  .alert-danger {
    border: none;
  }
  fgbcl-errors {
    p {
      position: absolute;
      top: -7px;
      left: 0;
    }
  }
}

fgbcl-short-long-description {
  .truncate-dots {
    color: $white !important;
  }
  .btn.btn-toggle {
    color: $white !important;
    font-family: $font-heading;
    font-size: 14px;
  }

  .btn.btn-toggle:active {
    border-color: transparent;
  }
}

fgb-terms-page fgbcl-terms-and-conditions {
  div * {
    background-color: $background-color !important;
    color: $white-system !important;
  }

  .terms-section-box {
    padding-left: 0px !important;
    padding-right: 0px !important;
    background-color: $background-color;
    border: none;

    .terms-section-content {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-weight: bold !important;
        font-family: $font-heading !important;
        font-size: 1rem;
      }

      p {
        font-family: $font-default;
        font-size: 1rem;
      }
    }
  }
}

fgbcl-faq-section {
  .faq-question-titles {
    font-size: 14px;
    font-family: $font-heading;
    font-weight: 400;
  }
  .card.card-body p {
    font-size: 12px;
    font-family: $font-default;
    font-weight: 400;
  }
}
